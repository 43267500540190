@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body{
    font-family: "Inter", sans-serif;
   
}
html{
    scroll-behavior: smooth;
}
.bg-cover{
    background-image: url(img/bannerBg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.btn.btn-primary{
    background-color: #00008A;
    border-radius: 5px;
    padding: 10px 14px ;
    font-size: 16px;
    color: #fff;
}
.about{
    background-image: url(img/aboutBg.png);
    background-repeat: no-repeat;
    background-position: bottom;
}
.developmentProcess{
    background-image: url(img/hireBg.svg);
    background-repeat: no-repeat;
    background-position: top;
}

.contactBg{
    background-image: url(img/contactBg.svg);
    background-repeat: no-repeat;
    background-position: top;
}

.hireBg{
    background-image: url(img/ourDevelopment.svg);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
}
.techIcon img{
    animation: float 6s ease-in-out infinite;
}
.techIcon img:nth-child(even){
    animation-delay: .5s;
}
.techIcon img:nth-child(odd){
    animation-delay: 1s;
}
@keyframes float {
	0% {		
		transform: translatey(0px);
	}
	50% {
		
		transform: translatey(-20px);
	}
	100% {
		
		transform: translatey(0px);
	}
}

@media (max-width:1600px){
  .techIcon img{
    position: static;
  }
  .techIcon{
    /* display: flex; */
    gap: 10px;
    align-items: center;
    width: 100%;
    margin: 10px auto 30px auto;    
    justify-content: space-between;
    flex-wrap: wrap;
    
  }
}
@media (max-width:767px){
    #navbar-default{
        display: none;
    }
    .open #navbar-default{
        display: block;
    }
}